import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://63fa277e17f202ed07d687a3747ce513@o4506631183597568.ingest.sentry.io/4506631292715008",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});